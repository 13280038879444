<template>
  <!--  复合组件-->
  <div>
    <van-checkbox-group v-model="checked">
    <div class="InTheLease" style="padding-bottom: 1rem">
      <div class="remainToBeEvaluated" v-for="(v,e) in listData" :key="v.id">
        <div class="listHeader">
          <div>
            <van-checkbox icon-size="24px" checked-color="#FB884F" v-show="v.deal==1" :name="v.id" @click="ifCheck(v)"></van-checkbox>
            <div>订单号：{{ v.runningWaterMark }}</div>
          </div>
          <div :class="v.statusStr=='原订单' ?'textColor':'noTextColor'">{{ v.statusStr =='待发货' ? '已发货' : v.statusStr}}</div>
        </div>
        <div class="listContent clearfix">
          <div class="listContentSubject" :class=" `${v.evaluateId==null ? '': 'noBorder' }  ${-1 !== [0,1,2,3,4,5].indexOf(v.orderStatus)? 'border':''}` "
               @click="lookAllOrder(v)">
            <img :src="v.productPic"/>
            <div style="flex: 1" class="clearfix">
              <div class="aboutListContentSubject">
                <div>
                  <h4>{{v.productName}}</h4>
                  <p>租期：{{ v.createTime|dataFormat }}-{{ v.expireTime|dataFormat }}</p>
                </div>
                <div class="num">×1</div>
              </div>
              <div class="totalRent">
                总租金:<span class="symbol">￥</span><span class="totalPrices">{{ v.orderMoney }}</span>
              </div>
            </div>
          </div>
          <!--        租赁中-->
          <div class="operation" v-show="v.orderStatus==2||v.orderStatus==3">
            <div class="throwALease " v-show="v.statusStr=='租赁中'||v.statusStr=='租赁已到期'">
              <div>差{{ v.maturityDay }}天到期</div>
              <div @click="surrenderOfTenancy(v,e)">退租</div>
            </div>
            <div class="relet" v-show="v.statusStr=='租赁已到期'">
              <div>已逾期{{v.overdueDay}}天</div>
              <div @click="reletButton(v,e)">续租</div>
            </div>
          </div>
          <!--        发货中-->
          <div class="operation" v-show="v.orderStatus==0||v.orderStatus==1">
            <div class="lookLogistics" @click="lookLogistics(v,e)">查看物流</div>
          </div>
          <!--        归还中-->

          <div class="operation" v-show="v.orderStatus==4">
            <div class="lookLogistics">归还中</div>
          </div>
<!--                  待评价-->
          <div class="operation" v-show="v.orderStatus==5">
            <div class="evaluateTxt" @click="evaluate(v,e)" v-show="v.evaluateId==null">待评价</div>
          </div>
        </div>
      </div>
    </div>
    </van-checkbox-group>
    <div class="msgPopUp">
      <van-popup v-model="msgPopUpShow" position="right" :style="{ height: '100%' ,width:'100%',paddingTop:'0.28rem'}">
<!--        评价-->
        <div class="evaluateMsg" v-show="show">
          <div class="evaluateTxt" v-model="evaluateData">
            <div class="evaluateTxtHeader">
              <img :src="evaluateData.productPic"/>
              <div class="evaluateTxtHeaderTitle">
                <h3>{{ evaluateData.productName }}</h3>
                <p>租期：{{ evaluateData.createTime|dataFormat }}-{{ evaluateData.expireTime|dataFormat }}</p>
              </div>
              <div class="evaluateTxtHeaderButton" @click="submitEvaluation">提交</div>
            </div>
            <div class="evaluateTxtContent">
<!--              <i class="iconfont icon-bianji1 evaluateTxtContentIcon"></i>-->
              <textarea placeholder="您的评价让我们做的更好!" class="textarea" style="resize:none " v-model="textareaTxt"></textarea>
            </div>
          </div>
        </div>
        <!--        续租弹框-->
        <div class="restParticulars" v-show="tenancyTermShow">
          <div class="restParticularsDetails" v-model="evaluateData">
            <div class="restParticularsDetailsHeader">
              <div>订单号：{{ evaluateData.runningWaterMark }}</div>
              <div>{{ evaluateData.statusStr }}</div>
            </div>
            <div class="restParticularsDetailsContent">
              <img :src="evaluateData.productPic"/>
              <div class="restParticularsDetailsContentDetails clearfix">
                <div>
                  <div class="restParticularsDetailsContentTime">
                    <h3>{{ evaluateData.productName }}</h3>
                    <p>租期：{{ evaluateData.createTime|dataFormat }}-{{ evaluateData.expireTime|dataFormat }}</p>
                  </div>
                  <div class="restParticularsDetailsContentNum">×1</div>
                </div>
                <div>
                  总租金：<span style="font-size: 0.33rem">￥</span><span style="font-weight: bold;font-size: 0.44rem">{{evaluateData.orderMoney}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="restParticularsPrice">
            <div>
              <van-cell title="请选择续租时间" :value="date" @click="dateShow = true">
                <!-- 使用 right-icon 插槽来自定义右侧图标 -->
                <template #right-icon>
                  <span style="font-size: 0.6rem;color: #999;" class="iconfont icon-rili"></span>
<!--                  <div class="iconfont icon-shouye1 ico"></div>-->
                </template>
              </van-cell>
              <!--              <van-cell title="预续租时间" :value="date" @click="dateShow = true"/>-->
              <van-calendar v-model="dateShow" :min-date="minDate" @confirm="onConfirm" color="#FB884F"/>
            </div>
<!--            <van-field v-model="customerRemark" label="备注" placeholder="备注" input-align="right" style="padding:0.28rem 0rem;font-size: 0.39rem"/>-->
            <div class="payPrice">
                <div>需要支付金额:<span>￥</span><span>{{addMoney}}</span>.00
                </div>
                <div>租期：{{addDay}}天</div>
            </div>
          </div>
          <div @click="leasePayments" class="leasePayments">立即支付</div>
        </div>

        <!--        快递物流-->
        <div class="logisticsDetails" v-show="logisticsDetailsShow">

          <div class="logisticsDetailsHeader">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-shunfeng"></use>
            </svg>
            <span>顺丰速运 {{mailNo}} </span>

          </div>
          <van-steps direction="vertical" :active="0" active-color="#FF9966" size="0.67rem"
                     style="border-radius: 0.28rem" >
            <van-step v-for="(routes,index) in routes" :key="index">
              <h3>{{routes.remark}}<span> {{routes.acceptTime}}</span></h3>
              <p>{{ routes.acceptAddress }}</p>
            </van-step>
          </van-steps>
        </div>
<!--  退租-->
        <div class="surrenderOfTenancy" v-show="surrenderOfTenancyShow">
          <div class="restParticularsDetails" v-model="evaluateData">
            <div class="restParticularsDetailsHeader">
              <div>订单号：{{ evaluateData.runningWaterMark }}</div>
              <div>{{ evaluateData.statusStr }}</div>
            </div>
            <div class="restParticularsDetailsContent">
              <img :src="evaluateData.productPic"/>
              <div class="restParticularsDetailsContentDetails clearfix">
                <div>
                  <div class="restParticularsDetailsContentTime">
                    <h3>{{ evaluateData.productName }}</h3>
                    <p>租期：{{ evaluateData.createTime|dataFormat }}-{{ evaluateData.expireTime|dataFormat }}</p>
                  </div>
                  <div class="restParticularsDetailsContentNum">×1</div>
                </div>
                <div >
                  总租金：<span style="color: #FB884F">￥</span><span style="color: #FB884F" >{{ evaluateData.orderMoney }}</span>
                </div>
              </div>
            </div>
          </div>
          <div v-show="this.evaluateData.receiveType!=1">
            <div class="logisticsCompany">
              <van-form>
                <van-field
                    readonly
                    clickable
                    name="picker"
                    :value="logisticsCompanyValue"
                    label="物流公司"
                    placeholder="请选择物流公司"
                    @click="modeOfPaymentShow = true"
                    input-align="right"
                    right-icon="arrow"
                    style="border-top-right-radius: 0.28rem;border-top-left-radius: 0.28rem;margin-top: 0.28rem;padding: 0.26667rem 0.42667rem"
                />
                <van-popup v-model="modeOfPaymentShow" position="bottom">
                  <van-picker
                      show-toolbar
                      :columns="modeOfPaymentColumns"
                      @confirm="modeOfPaymentOnConfirm"
                      @cancel="modeOfPaymentShow = false"
                  />
                </van-popup>
                <van-field
                    v-model="trackingNumberValue"
                    label="快递单号"
                    placeholder="请输入快递单号"
                    input-align="right"
                    style="border-bottom-right-radius: 0.28rem;border-bottom-left-radius: 0.28rem;padding: 0.26667rem 0.42667rem"
                />
              </van-form>
            </div>
            <div class="shootTheExpressOrder" >
              <div class="moveUp">
                <span>*</span>
                <span>拍摄快递面单</span>

                <span>请确保上传的照片</span>
                <span>信息真实有效、清晰可读</span>
              </div>
              <div>
                <van-uploader v-model="fileList1" capture :after-read="afterRead" preview-size="105px" :max-count="1"
                              upload-icon="plus" style="padding-left: 0;margin-top: -0.04rem;" class="upIdCode">
                  <template>
                    <img src="../../images/handIdCardicons.png"/>
                  </template>
                </van-uploader>
              </div>
            </div>
          </div>

          <div class="surrenderOfTenancyBtn">
            <div @click="surrenderOfTenancySubmit">确认</div>
          </div>
        </div>
        <div @click="msgPopUpShow=false" class="msgPopUpShowStyle">
<!--          <van-icon name="arrow-left"/>-->
          <img src="../../images/backPre.png" alt="">
        </div>
      </van-popup>
    </div>
  </div>

</template>

<script>
import {Notify, Toast} from "vant";
import orderPopUp from './orderPopUp'
import leaseApi from "../../../utils/leaseApi";
import qs from "qs";
export default {
  name: "InTheLease",
  props: ['listData'],
  components: {
    orderPopUp
  },
  data() {
    return {
      show: false,
      date: '',
      dateShow: false,
      msgPopUpShow: false,
      tenancyTermShow: false,
      logisticsDetailsShow: false,
      surrenderOfTenancyShow: false,
      checked: [],
      logisticsCompanyValue: '',//物流公司
      modeOfPaymentShow: false,
      modeOfPaymentColumns: ['顺丰快递', '申通快递', '天天快递', '圆通快递'],
      trackingNumberValue: '',//快递单号
      textareaTxt:'',
      priceData : [],
      minDate : "",
      evaluateData: {
        id:'',
        productId:'',
        productPic: '',
        productName: '',
        createTime: '',
        expireTime: '',
        runningWaterMark: '',
        statusStr: '',
        maturityDay: '',
        orderMoney:'',
        evaluateId:'',
        childrenOrder:[],
        commodityId:'',
        receiveType:'',
      },
      fileList: [],
      fileList1:[],
      sf:[],
      mailNo:"",
      routes:[],
      orderMoney:"",
      orderSize:0,
      addDay: 0,
      addMoney:0,
      customerRemark:"",
      // pattern:/[a-zA-Z][0-9]/g,
      childrenOrderList:[],
    };
  },
  methods: {
    // 评论
    evaluate(e, index) {
      console.log(e)
      this.logisticsDetailsShow=false
      this.tenancyTermShow=false
      this.surrenderOfTenancyShow=false
      this.msgPopUpShow=true
      this.show=true
      this.evaluateData.productPic = e.productPic
      this.evaluateData.productName = e.productName
      this.evaluateData.createTime = e.createTime
      this.evaluateData.expireTime = e.expireTime
      this.evaluateData.id=e.id
      this.evaluateData.productId=e.commodityId
    },
    async submitEvaluation() {
      if(this.textareaTxt==""){
        Toast('请输入评价内容');
        return
      }
      console.log(this.evaluateData)
      const { code,data}=await leaseApi.saveEvaluate({evaluate:this.textareaTxt,orderId:this.evaluateData.id,productId:this.evaluateData.productId})
      if(code === 200){
        Notify({type: 'success', message: '评论成功'});
        this.msgPopUpShow=false
      }
    },
    // 续租
    async reletButton(e, index) {
      // console.log(e)
      this.show=false
      this.surrenderOfTenancyShow=false
      this.logisticsDetailsShow=false
      this.msgPopUpShow=true
      this.tenancyTermShow=true
      this.evaluateData.productPic = e.productPic
      this.evaluateData.productName = e.productName
      this.evaluateData.createTime = e.createTime
      this.evaluateData.expireTime = e.expireTime
      this.evaluateData.runningWaterMark = e.runningWaterMark
      this.evaluateData.statusStr = e.statusStr
      this.evaluateData.orderMoney = e.orderMoney
      this.evaluateData.id = e.id
      this.evaluateData.commodityId = e.commodityId
      const { data } = await leaseApi.getReturnRentPrice({productId: e.commodityId})
      this.priceData = data;
      // console.log(this.priceData);
    },
    // 续租支付
    async leasePayments(e) {
      await Toast.loading({
        message: '下单中..',
        forbidClick: true,
        duration : 0,
        loadingType: 'spinner',
      });
      if (this.addDay<1){
        Toast.fail("续租天数必须大于1")
        return
      }
      const { data, msg, code } = await leaseApi.createReNewOrder({"commodityId":this.evaluateData.commodityId,"customerRemark":this.customerRemark,"originalOrder":this.evaluateData.id,"rentDay":this.addDay})
      if (code === 200){
        Toast.success("下单成功")
        await  this.weChatPay(data)
        await this.$router.push({name:'order'})
      }
      if (code === 500){
        Toast.fail(msg)
      }
    },
    /**
     * @param  {string} date 起始日期
     * @param  {number} day 向后的天数
     * @return {string} 返回想要得到的日期
     */
    convertDate(date, day) {
      let tempDate = new Date(date);
      tempDate.setDate(tempDate.getDate() + day);
      let Y = tempDate.getFullYear();
      let M = tempDate.getMonth() + 1 < 10 ? '0' + (tempDate.getMonth() + 1) : tempDate.getMonth() + 1;
      let D = tempDate.getDate() < 10 ? '0' + (tempDate.getDate()) : tempDate.getDate();
      return (Y + "/" + M + "/" + D);
    },
    weChatPay(data){
      WeixinJSBridge.invoke('getBrandWCPayRequest',data,
          function(res) {
            if (res.err_msg == "get_brand_wcpay_request:ok") {
              // 支付成功
              Toast.success('支付成功');
              setTimeout(function () {
                window.location.href = 'http://maplays.net/#/order'
              },2000)
            }else if(res.err_msg == "get_brand_wcpay_request:cancel"){
              // 用户取消
              Toast.fail("取消支付");
            }else if(res.err_msg == "get_brand_wcpay_request:fail"){
              // 支付失败
              alert("支付失败");
            }else {
              //其他原因
              alert("有其他原因");
            }
          });
    },
    // 退租
    surrenderOfTenancy(e) {
      this.show=false
      this.logisticsDetailsShow=false
      this.tenancyTermShow=false
      this.msgPopUpShow=true
      this.surrenderOfTenancyShow=true
      this.evaluateData.productPic = e.productPic
      this.evaluateData.productName = e.productName
      this.evaluateData.createTime = e.createTime
      this.evaluateData.expireTime = e.expireTime
      this.evaluateData.runningWaterMark = e.runningWaterMark
      this.evaluateData.statusStr = e.statusStr
      this.evaluateData.orderMoney = e.orderMoney
      this.evaluateData.id = e.id
      this.evaluateData.productId=e.commodityId
      this.evaluateData.receiveType=e.receiveType
      console.log(e)
    },

    modeOfPaymentOnConfirm(value) {
      this.logisticsCompanyValue = value;
      this.modeOfPaymentShow = false;
    },
    // 查看同商品历史订单
    lookAllOrder(e) {
      console.log(e)
      this.$router.push({name: "orderHistory",params:{'orderId':e.id,'orderMoney':e.orderMoney}})
    },
    // 查看物流
    async lookLogistics(e) {
      this.show=false
      this.tenancyTermShow=false
      this.surrenderOfTenancyShow=false
      this.msgPopUpShow=true
      this.logisticsDetailsShow=true

      const { data } = await leaseApi.orderSearch({"deliverySn":e.deliverySn})
      console.log(data)
      this.sf=data
      this.mailNo = this.sf.routeResps[0].mailNo
      this.routes = this.sf.routeResps[0].routes.reverse()
    },
    formatDate(date) {
      return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
    },
    async onConfirm(date) {
      this.dateShow = false;
      this.date = this.formatDate(date);
      //选择的时间
      let endDate = new Date(date).getTime()
      // 当前时间
      let newDate =  new Date()
      newDate.setHours(0)
      newDate.setMinutes(0)
      newDate.setSeconds(0)
      newDate.setMilliseconds(0)
      // 计算时间差
      let d = endDate - newDate.getTime()
      let c = parseInt(Math.abs(d)/1000 /60 /60 /24)
      this.addDay = c
      let priceMap = {}
      let keys =  this.priceData.map(p => {
        priceMap[p.fate] = p.unitPrice
        return p.fate
      })
      keys.forEach(k => {
        let a = k.substring(1,k.length - 1)
        let split = a.split("-");
        if (parseInt(split[0]) <= this.addDay && parseInt(split[1]) >= this.addDay && this.addDay !== 0 ){
          return this.addMoney = priceMap[k] * this.addDay
        }
      })
    },
    afterRead(file){
      let formData=new FormData()
      //判断图片的大小，单位是字节
      if (file.file.size > 1048576) {
        console.log('压缩执行')
        let canvas = document.createElement('canvas') // 创建Canvas对象(画布)
        let context = canvas.getContext('2d')
        let img = new Image()
        img.src = file.content // 指定图片的DataURL(图片的base64编码数据)
        img.onload = () => {
          canvas.width = 400
          canvas.height = 300
          context.drawImage(img, 0, 0, 400, 300)
          file.content = canvas.toDataURL(file.file.type, 0.92) // 0.92为默认压缩质量
          let files = this.dataURLtoFile(file.content, file.file.name)
          this.fileList.push(files)
        }
      } else {
        // 符合大小的，直接上传
        this.fileList.push(file.file)
      }
    },
    dataURLtoFile(dataurl, filename) {
      let arr = dataurl.split(',')
      let mime = arr[0].match(/:(.*?);/)[1]
      let bstr = atob(arr[1])
      let n = bstr.length
      let u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename, {
        type: mime
      })
    },

    // 提交退还信息
     async surrenderOfTenancySubmit() {
      const _this=this
      if(_this.evaluateData.receiveType=='1'){
        const forms = new FormData();
        forms.append('orderId',this.evaluateData.id)
        forms.append('runningWaterMark',this.evaluateData.runningWaterMark)
        const {code}=await leaseApi.returnRentBelow(forms)
        console.log(code)
        if(code==200){
          await Toast.loading({
            message: '提交中...',
            forbidClick: true,
          });
          setTimeout(function (){
            Toast.loading({
              message:'提交成功' ,
              forbidClick: true,
              loadingType: 'spinner',
            });
            this.$router.push({name:'personage'})
          }.bind(this),2000)}
      }else if (_this.evaluateData.receiveType=='0') {
        if(this.logisticsCompanyValue== ''){
          Toast.fail('请填写物流公司')
          return;
        }else if(this.trackingNumberValue == ''){
          Toast.fail('请填写快递单号')
          return;
        }else if(!(/^[0-9a-zA-Z]*$/g.test(this.trackingNumberValue))){
          Toast.fail('请填写正确的快递单号')
          return;
        }else if(this.fileList1 == ''){
          Toast.fail('请上传快递单号图片')
          return;
        }
        const forms = new FormData();
        forms.append("file",this.fileList[0])
        forms.append('orderId',this.evaluateData.id)
        forms.append('returnDeliveryCompany',this.logisticsCompanyValue)
        forms.append('returnLogisticsOdd',this.trackingNumberValue)
        forms.append('runningWaterMark',this.evaluateData.runningWaterMark)
        const {code,data}=await leaseApi.returnRent(forms)
        if(code==200){
          await Toast.loading({
            message: '提交中...',
            forbidClick: true,
          });
          setTimeout(function (){
            Toast.loading({
              message:'提交成功' ,
              forbidClick: true,
              loadingType: 'spinner',
            });
            this.$router.push({name:'personage'})
          }.bind(this),2000)
        }
      }
      // console.log(this.fileList[0].file);
    },
    //传递选择订单的长度
    ifCheck(e){
      //监听 checked 的长度 随着长度的变换 加减 orderMoney
      if (this.checked.length>this.orderSize ){
        this.orderMoney = (this.orderMoney * 1 ) + (e.orderMoney * 1)
      }if (this.checked.length<this.orderSize){
        this.orderMoney = (this.orderMoney * 1 ) - (e.orderMoney * 1)
      }
      console.log(this.orderMoney)
      this.orderSize = this.checked.length
      e.childrenOrder.forEach(id => {
        this.childrenOrderList.push(id)
      })
      // this.childrenOrderList.push(e.childrenOrder)
      this.$emit("check",this.checked,this.orderMoney,this.childrenOrderList)
    }
  },
  created() {
    let date = {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      date: new Date().getDate()
    }
    let nowDateStr = date.year + '/' + date.month + '/' + date.date
    this.minDate = new Date(this.convertDate(nowDateStr, 1).replace('-', '/'));
  }
}
</script>

<style lang="less" scoped>
.remainToBeEvaluated {
  background: #FFFFFF;
  border-radius: 0.21rem;
  padding: 0.32rem 0.56rem 0.33rem 0.54rem;
  margin-bottom: 0.21rem;

  .listHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.46rem;

    div:nth-child(1) {
      display: flex;
      //align-items: center;
      > div {
        font-size: 0.39rem;
        font-family: xiaocheng;
        font-weight: 400;
        color: #333333;
        margin-right: 0.1rem;
        align-items: center;
        display: flex;
      }
    }

    div:nth-child(2) {
      font-size: 0.39rem;
      font-family: xiaocheng;
      font-weight: 400;
      color: #FB884F;
    }
  }

  .listContent {
    .listContentSubject {
      display: flex;

      img {
        width: 2.47rem;
        height: 2.53rem;
        background: #FFFFFF;
        border: 1px solid #EEEEEE;
        border-radius: 0.28rem;
        margin-right: 0.33rem;
      }

      .aboutListContentSubject {
        display: flex;
        flex: 1;
        justify-content: space-between;

        h4 {
          font-size: 0.44rem;
          font-family: xiaocheng;
          font-weight: 500;
          color: #333333;
          margin-bottom: 0.44rem;
          margin-top: 0.28rem;
        }

        p {
          font-size: 0.36rem;
          font-family: xiaocheng;
          font-weight: 400;
          color: #333333;
        }

        .num {
          font-size: 0.36rem;
          font-family: xiaocheng;
          font-weight: 400;
          color: #333333;
          margin-top: 0.28rem;
        }
      }

      .totalRent {
        font-size: 0.42rem;
        font-family: xiaocheng;
        font-weight: 400;
        color: #333333;
        float: right;
        margin-top: 0.65rem;

        .symbol {
          font-size: 0.33rem;
          font-family: xiaocheng;
          font-weight: 400;
        }

        .totalPrices {
          font-size: 0.44rem;
          font-family: xiaocheng;
          font-weight: bold;
        }
      }
    }

    .border {
      border-bottom: 0.01rem solid #EFEFEF;
      padding-bottom: 0.43rem;
      margin-bottom: 0.03rem;
    }

    .operation {
      display: flex;
      float: right;

      .throwALease {
        position: relative;

        div:nth-child(1) {
          position: absolute;
          background: linear-gradient(90deg, #F47542 0%, #F85B24 100%);
          border-radius: 0.4rem;
          font-size: 0.28rem;
          font-family: xiaocheng;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 0.42rem;
          text-align: center;
          padding: 0.08rem 0.17rem;
          top: -0.4rem;
          left: 0px;
        }

        div:nth-child(2) {
          width: 2.47rem;
          height: 0.83rem;
          background: #FFFFFF;
          border: 0.02rem solid #CCCCCC;
          border-radius: 0.39rem;
          font-size: 0.39rem;
          font-family: xiaocheng;
          font-weight: 400;
          color: #333333;
          line-height: 0.83rem;
          text-align: center;
        }
      }

      .relet {
        margin-left: 0.22rem;
        position: relative;

        div:nth-child(1) {
          position: absolute;
          width: 1.56rem;
          background: linear-gradient(90deg, #F47542 0%, #F85B24 100%);
          border-radius: 0.4rem;
          font-size: 0.28rem;
          font-family: xiaocheng;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 0.42rem;
          text-align: center;
          padding: 0.08rem 0.17rem;
          top: -0.4rem;
          left: 0rem;
        }

        div:nth-child(2) {
          width: 2.47rem;
          height: 0.83rem;
          background: #FFFFFF;
          border: 0.03px solid #CCCCCC;
          border-radius: 0.42rem;
          font-size: 0.39rem;
          font-family: xiaocheng;
          font-weight: 400;
          color: #333333;
          line-height: 0.83rem;
          text-align: center;
        }
      }

      .lookLogistics {
        width: 2.47rem;
        height: 0.83rem;
        background: #FFFFFF;
        border: 0.03px solid #CCCCCC;
        border-radius: 0.42rem;
        font-size: 0.39rem;
        font-family: xiaocheng;
        font-weight: 400;
        color: #999999;
        line-height: 0.83rem;
        text-align: center;
      }

      .applicationOfMakeOutAnInvoice {
        width: 2.47rem;
        height: 0.83rem;
        background: #FFFFFF;
        border: 0.03px solid #CCCCCC;
        border-radius: 0.42rem;
        font-size: 0.39rem;
        font-family: xiaocheng;
        font-weight: 400;
        color: #333333;
        line-height: 0.83rem;
        text-align: center;
      }

      .evaluateTxt {
        width: 2.47rem;
        height: 0.83rem;
        background: #FFFFFF;
        border: 0.03px solid #CCCCCC;
        border-radius: 0.42rem;
        font-size: 0.39rem;
        font-family: xiaocheng;
        font-weight: 400;
        color: #CCCCCC;
        line-height: 0.83rem;
        text-align: center;
        margin-left: 0.22rem;
      }
    }
  }
}

.clearfix:after { /*伪元素是行内元素 正常浏览器清除浮动方法*/
  content: "";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.evaluateMsg {
  margin-top: 0.1rem;
  .evaluateTxt {
    background: #FFFFFF;
    border-radius: 0.28rem;
    padding: 0.32rem 0.33rem 0.44rem 0.33rem;

    .evaluateTxtHeader {
      display: flex;
      margin-bottom: 0.44rem;

      img {
        width: 1.44rem;
        height: 1.44rem;
        margin-right: 0.33rem;
      }

      .evaluateTxtHeaderTitle {
        align-self: center;

        h3 {
          font-size: 0.44rem;
          font-family: xiaocheng;
          font-weight: bold;
          color: #333333;
        }

        p {
          font-size: 0.33rem;
          font-family: xiaocheng;
          font-weight: 400;
          color: #999999;
        }
      }

      .evaluateTxtHeaderButton {
        align-self: center;
        width: 1.53rem;
        height: 0.78rem;
        background: #FB884F;
        border-radius: 0.14rem;
        font-size: 0.36rem;
        font-family: xiaocheng;
        font-weight: 400;
        color: #FFFFFF;
        text-align: center;
        //line-height: 0.78rem;
        margin-left: auto;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .evaluateTxtContent {
      width: 100%;
      position: relative;

      textarea {
        width: 100%;
        height: 4.11rem;
        background: #FFFFFF;
        border: 0.03px solid #EEEEEE;
        border-radius: 0.28rem;
        text-indent: 0.2rem;
        padding-top: 0.2rem;
        font-size: 0.36rem;
      }

      textarea::placeholder {
        font-size: 0.36rem;
        font-family: xiaocheng;
        font-weight: 400;
        color: #999999;
      }

      .evaluateTxtContentIcon {
        position: absolute;
        top: 0.43rem;
        left: 0.35rem;
        font-size: 0.42rem;
        color: #999999;
      }
    }
  }
}

.restParticulars {
    padding: 0rem 0.32rem;
  .restParticularsPrice {
    background: #FFFFFF;
    margin-top: 0.28rem;
    border-radius: 0.28rem;
    padding: 0.32rem 0.6rem 0.42rem 0.54rem;

    .payPrice {
      display: flex;
      justify-content: space-between;
      margin-top: 0.4rem;
      align-items: center;
      div:nth-child(1){
        font-size: 0.4rem;
        font-family: xiaocheng;
        font-weight: 500;
        color: #333333;
        span:nth-child(1){
          font-size:0.32rem ;
        }
        span:nth-child(2){
          font-size:0.48rem;
        }
      }
      div:nth-child(2){
        font-size: 0.37rem;
        font-family: xiaocheng;
        font-weight: 400;
        color: #333333;
      }
    }
  }
  .leasePayments{
    width: 8.37rem;
    height: 1.04rem;
    background: #FB884F;
    box-shadow: 0rem 0rem 0rem 0rem rgba(251,140,82,0.1300);
    border-radius: 0.52rem;
    font-size: 0.37rem;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
    line-height:  1.04rem;
    text-align: center;
    margin: 0 auto;
    margin-top: 0.56rem;
  }

}

.logisticsDetails {
  background: #FFFFFF;
  padding-top: 0.44rem;
  border-radius: 0.28rem;

  .logisticsDetailsHeader {
    padding-left: 0.25333rem;
    margin-bottom: 0.6rem;

    .icon {
      font-size: 0.42rem;
      margin-right: 0.6rem;
    }

    > span {
      font-size: 0.39rem;
      font-family: xiaocheng;
      font-weight: 400;
      color: #333333;
    }
  }
}

/deep/ .van-popup--right {
  padding-top: 0.33rem;
  background: #F6F6F6;
  overflow-y: inherit;
}

/deep/ .van-cell {
  padding: 0rem;
  font-size: 0.42rem;
  font-family: xiaocheng;
  font-weight: 500;
  color: #333333;
  border-bottom: 0.01rem solid #ebedf0;
  padding-bottom: 0.28rem;
}

/deep/ .van-icon {
align-self: center;
font-size: 0.39rem!important;
}
/deep/.van-cell::after{
border-bottom: 0rem;
}
/deep/ .van-icon {
font-size: 0.42rem;
}
/deep/.van-checkbox{
  align-items: baseline;
}
/deep/.van-checkbox__icon{
  height: 0.5rem;
  line-height: 0.5rem;
}
/deep/ .van-step--vertical {
padding: 0.26667rem 0.26667rem 0.26667rem 0.47rem;
}

.textColor {
color: #333333 !important;
}

.restParticularsDetails {
background: #FFFFFF;
padding: 0.32rem 0.6rem 0.42rem 0.54rem;
border-radius: 0.28rem;

.restParticularsDetailsHeader {
display: flex;
justify-content: space-between;
margin-bottom: 0.46rem;

div:nth-child(1) {
font-size: 0.39rem;
font-family: Source Han Sans CN;
font-weight: 400;
color: #333333;
}

div:nth-child(2) {
font-size: 0.39rem;
font-family: Source Han Sans CN;
font-weight: 400;
color: #FB884F;
}
}

.restParticularsDetailsContent {
display: flex;

img {
width: 2.47rem;
height: 2.56rem;
background: #FFFFFF;
border: 0.01px solid #EEEEEE;
border-radius: 0.28rem;
margin-right: 0.33rem;
}

.restParticularsDetailsContentDetails {
flex: 1;
margin-top: 0.28rem;

> div:nth-child(1) {
  display: flex;
  justify-content: space-between;

  .restParticularsDetailsContentTime {
    h3 {
      font-size: 0.44rem;
      font-family: xiaocheng;
      font-weight: 500;
      color: #333333;
      margin-bottom: 0.44rem;
    }

    p {
      font-size: 0.36rem;
      font-family: xiaocheng;
      font-weight: 400;
      color: #333333;
    }
  }

  .restParticularsDetailsContentNum {
    font-size: 0.36rem;
    font-family: xiaocheng;
    font-weight: 400;
    color: #333333;
    align-self: center;
  }
}

> div:nth-child(2) {
  float: right;;
  font-size: 0.42rem;
  font-family: xiaocheng;
  font-weight: 400;
  color: #333333;
  margin-top: 0.65rem;
}
}
}
}

.logisticsCompany {
height: 100px;
}

.shootTheExpressOrder {
background: #FFFFFF;
border-radius: 0.28rem;
//padding: 0.33rem 0rem 0.33rem 0.56rem;
margin-top: 1.28rem;
padding: 0.26667rem 0.22667rem;
  @media screen and (min-width: 320px){
    .moveUp{
      margin-top: -0.3rem;
    }
  }

> div:nth-child(1) {
span:nth-child(2) {
font-size: 0.31rem;
font-family: Source Han Sans CN;
font-weight: 400;
color: #292929;
}

span:nth-child(1) {
font-size: 0.42rem;
font-family: Source Han Sans CN;
font-weight: 400;
color: #FB884F;
}

span:nth-child(3) {
font-size: 0.31rem;
font-family: Source Han Sans CN;
font-weight: 400;
color: #333333;
}

span:nth-child(4) {
font-size: 0.31rem;
font-family: Source Han Sans CN;
font-weight: 400;
}
}

> div:nth-child(2) {
margin-top: 0.28rem;

img {
width: 3.89rem;
}
}
}

.surrenderOfTenancy {
position: relative;
height: 100%;

.surrenderOfTenancyBtn {
width: 100%;
background: #FFFFFF;
position: absolute;
bottom: 0px;
padding: 0.28rem 0rem;

div {
width: 9.75rem;
height: 1.11rem;
background: #FB884F;
box-shadow: 0rem 0rem 0rem 0rem rgba(251, 140, 82, 0.13);
border-radius: 1rem;
font-size: 0.44rem;
font-family: Microsoft YaHei;
font-weight: 400;
color: #FFFFFF;
line-height: 1.11rem;
text-align: center;
margin-left: 50%;
transform: translateX(-50%);
}
}
}

.msgPopUpShowStyle {
position: fixed;
left: 0.1rem;
top: 82%;
width: 1.083rem;
height: 1.083rem;
text-align: center;
background: #F6F6F6;
border-radius: 50%;
line-height: 70px;
  z-index: 111;
  img{
    width: 100%;
  }
}
@media screen and ( max-width: 320px) {
  .msgPopUpShowStyle {
    top: 83%;

  }
}
/deep/.van-field__label{
  color: #333;
}
/deep/.van-cell--required::before{
  color: #FB884F;
}
/deep/.van-steps{
  height: 16rem;
  //padding-bottom: 1rem;
  overflow: scroll;
}
.noBorder{
  border-bottom:0rem solid transparent !important;
}
</style>
