<template>
  <div>
    <van-popup v-model="show" position="top" :style="{ height: '30%' }"></van-popup>
  </div>
</template>

<script>
export default {
  name: "orderPopUp"
}
</script>

<style scoped>

</style>